import { Card, CardBody, Select, SelectItem, Switch } from "@nextui-org/react";
import {
  ConditionalFilter,
  CrudFilter,
  LogicalFilter,
  useEditButton,
} from "@refinedev/core";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTableFilter } from "../../../../context/table-filter.context";

const ActiveMembershipKey = "membership";

export const ActiveMembershipFilter = () => {
  const { setFilters, filters } = useTableFilter();
  const updateFilters = (newFilters: CrudFilter[]) => {
    setFilters(newFilters, "replace");
  };

  // const [value, setValue] = React.useState(new Set([]));
  //
  // useEffect(() => {
  //   console.log({
  //     value,
  //     active: value.has("active"),
  //     inactive: value.has("inactive"),
  //   });
  //   const active = value.has("active");
  //   const inactive = value.has("inactive");
  //   setFilter(active ? "active" : inactive ? "inactive" : null);
  // }, [value]);
  /**
   *
   * The function returns filters based on the match condition. If match is set to true, it retuns the Active membership filter,
   * if it exists. If the match is set to false, it returns all the fields, without the active membership filter
   *
   */
  const iterateOverFilters = (filters: CrudFilter[], match: boolean) => {
    return filters.filter((filter) =>
      match
        ? (filter as ConditionalFilter).key === ActiveMembershipKey
        : (filter as ConditionalFilter).key !== ActiveMembershipKey
    );
  };

  const activeMembershipFilter = useMemo(
    () => iterateOverFilters(filters, true)?.[0],
    [filters]
  );

  /**
   * Updates the filters by setting a filter based on the provided value. If the value is
   * an array of numbers and non-empty, it adds a new filter object for the specific field
   * with the "in" operator. If the value is undefined or an empty array, it removes any
   * existing filter for the specified field.
   */
  const setFilter = (selection: string | null) => {
    const selectionFilter: CrudFilter[] =
      selection === "active"
        ? [
            {
              field: "membership.startDate",
              operator: "lte",
              value: new Date().toISOString().substring(0, 10),
            },
            {
              field: "membership.endDate",
              operator: "gte",
              value: new Date().toISOString().substring(0, 10),
            },
          ]
        : [
            {
              field: "membership.startDate",
              operator: "gte",
              value: new Date().toISOString().substring(0, 10),
            },
            {
              field: "membership.endDate",
              operator: "lte",
              value: new Date().toISOString().substring(0, 10),
            },
            {
              field: "membership.id",
              operator: "null",
              value: "",
            },
          ];

    const updatedFilters = selection
      ? iterateOverFilters(filters, false).concat({
          key: ActiveMembershipKey,
          operator: selection === "active" ? "and" : "or",
          value: selectionFilter,
        })
      : iterateOverFilters(filters, false);

    updateFilters(updatedFilters);
  };

  const getSelectedKey = useCallback(() => {
    const conditionalFilter = filters.find(
      (el) => (el as ConditionalFilter).key === ActiveMembershipKey
    )?.value;
    if (!conditionalFilter) {
      return [];
    }
    let selection = "active";
    if (Array.isArray(conditionalFilter)) {
      const isInactive = !!conditionalFilter.find(
        (el) => el.field === "membership.id"
      );
      if (isInactive) {
        selection = "inactive";
      }
    }
    return [selection];
  }, [filters]);

  return (
    <Select
      className="max-w-xs"
      labelPlacement={"inside"}
      label="Membership"
      placeholder="All"
      selectedKeys={getSelectedKey()}
      onSelectionChange={(selection) => {
        if (selection === "all") {
          setFilter(null);
        } else {
          setFilter(
            selection.has("active")
              ? "active"
              : selection.has("inactive")
              ? "inactive"
              : null
          );
        }
      }}
    >
      <SelectItem key={"active"}>Active membership</SelectItem>
      <SelectItem key={"inactive"}>No membership</SelectItem>
    </Select>
  );
};
