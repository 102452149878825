import { Avatar, cn, Image } from "@nextui-org/react";
import { useApiUrl } from "@refinedev/core";
import React from "react";
import { useAssociationUserShow } from "../../../../context/user-show.context";
import { useFileEntityUrl } from "../../../../hooks";
import { getCountryFlagUrl } from "../../../../lib/flag-map";
import { getValidMembership } from "../../../../lib/utils";
import { UserViewCard } from "./user-view-card";
import { format } from "date-fns";
import { get } from "lodash";

const SPECIAL_RULES: Record<string, Record<string, any>> = {
  holland: {
    logo: {
      width: 150,
      height: 125,
    },
    watermark: {
      width: "900px",
      height: "450px",
      right: "-60px",
      bottom: "-130px",
      class: "rotate-[-7deg] -skew-y-12",
    },
  },
  italy: {
    logo: {
      width: 75,
      height: 75,
    },
    watermark: {
      class: "-rotate-[13deg]",
      height: "410px",
      width: "410px",
      bottom: "-25px",
      right: "-25px",
    },
  },
  slovakia: {
    logo: {
      width: 110,
      height: 100,
      class: "bg-none shadow-none backdrop-blur-none",
    },
    watermark: {
      class: "rotate-0 w-full -bottom-24",
    },
  },

  iceland: {
    watermark: {
      class: "scale-90 opacity-100 rotate-[-17deg]",
      bottom: "-35px",
      right: "-35px",
    },
  },

  jamaica: {
    logo: {
      width: 98,
      height: 75,
    },
    watermark: {
      class: "w-full h-full scale-[135%] -bottom-32 -left-10",
    },
  },

  malta: {
    watermark: {
      class: "-bottom-32 -right-8 h-full w-full scale-[120%]",
    },
  },
  albania: {
    logo: {
      height: 125,
      width: 130,
      class: "rounded-none",
    },
    watermark: {
      class: "-bottom-32 h-full w-full",
    },
  },
  belgium: {
    associationText: (
      <>
        The Royal <br />
        <span className={"font-bold"}>PGA of Belgium</span>
      </>
    ),
    watermark: {
      class: "rotate-0 w-full -bottom-2 -right-2",
    },
  },
};

export const PgaUserCard = () => {
  const { associationUser } = useAssociationUserShow();
  const country =
    associationUser?.association?.country?.toLowerCase() || "default";
  return (
    <UserViewCard
      additionalClasses={`bg-gradient-to-b bg-blue-900 background-${country}`}
    >
      <AssociationBackgroundImage />
      <div className={"flex flex-col px-6 py-4 h-full mb-3"}>
        <AssociationInfoBar />
        <UserImage />
        <UserInfoName />
        <CountryFlag />
        <UserDetails />
      </div>
    </UserViewCard>
  );
};

const AssociationBackgroundImage = () => {
  const watermarkIds: Record<string, string> = {
    italy: "f35c1f01-cb94-44d3-ac41-4f31400e8751",
    iceland: "605c0617-3cce-4d8b-89d7-134372e5a040",
    slovakia: "13afcc8e-49ef-4da8-b69e-b243dd5f5925",
    jamaica: "aa99e7aa-b1d9-4016-b868-7fbc29b76a93",
    holland: "4ec8f550-7c36-4df5-b13d-3cc5fe04d055",
    albania: "b15ae493-dc11-42e7-bd5b-5080f7392670",
    malta: "21d2c83e-cc40-4a87-8b2d-1fc2404d0353",
    belgium: "e52661be-b2f8-4d0f-b37e-1e34fb790778",
  };
  const apiUrl = useApiUrl();
  const { associationUser } = useAssociationUserShow();
  const country = associationUser?.association?.country?.toLowerCase();

  return associationUser?.association?.logoId ? (
    <div
      className={cn(
        "absolute opacity-[7%] overflow-hidden z-0 overscroll-none",
        getSpecialRuleOrDefault(country, "watermark.class", "")
      )}
      style={{
        width: `${getSpecialRuleOrDefault(
          country,
          "watermark.width",
          undefined
        )}`,
        height: `${getSpecialRuleOrDefault(
          country,
          "watermark.height",
          undefined
        )}`,
        right: `${getSpecialRuleOrDefault(
          country,
          "watermark.right",
          undefined
        )}`,
        bottom: `${getSpecialRuleOrDefault(
          country,
          "watermark.bottom",
          undefined
        )}`,
      }}
    >
      {watermarkIds[
        associationUser?.association?.country?.toLowerCase() || ""
      ] && (
        <img
          src={`${apiUrl}/files/${
            watermarkIds[
              associationUser?.association?.country?.toLowerCase() || ""
            ]
          }`}
          className={"object-cover w-full h-full"}
        />
      )}
    </div>
  ) : null;
};

const AssociationInfoBar = () => {
  const { associationUser } = useAssociationUserShow();
  const country = associationUser?.association?.country?.toLowerCase();
  const apiUrl = useApiUrl();
  return (
    <div className={"grid grid-cols-2 justify-between mt-2 h-[75px] "}>
      <div className={"flex items-center"}>
        <div className={"text-xs text-light/90"}>
          {country && SPECIAL_RULES[country]?.associationText ? (
            <>{SPECIAL_RULES[country].associationText}</>
          ) : (
            <>
              Professional Golfers' <br />
              <span className={"font-semibold"}>
                Association of {associationUser?.association?.country}
              </span>
            </>
          )}
        </div>
      </div>
      <div className={"flex flex-col items-end justify-center h-[75px]"}>
        {associationUser?.association?.logoId && (
          <Image
            className={`${getSpecialRuleOrDefault(country, "logo.class", "")}`}
            src={`${apiUrl}/files/${associationUser.association?.logoId}`}
            width={getSpecialRuleOrDefault(country, "logo.width", 75)}
            height={getSpecialRuleOrDefault(country, "logo.height", 75)}
          />
        )}
      </div>
    </div>
  );
};

const UserImage: React.FC = () => {
  const { associationUser } = useAssociationUserShow();
  return (
    <div className={"flex justify-center z-10 w-full relative mt-5 w-[125px]"}>
      <Avatar
        className={
          "w-[125px] h-[125px] text-large drop-shadow-xl shadow-sm shadow-black border-none"
        }
        src={useFileEntityUrl(associationUser?.user?.photoId)}
        name={`${associationUser?.user?.firstName} ${associationUser?.user?.lastName}`}
        color={"secondary"}
      ></Avatar>
    </div>
  );
};

const UserInfoName: React.FC = () => {
  const { associationUser } = useAssociationUserShow();
  return (
    <div className={"flex justify-center z-10 mt-2"}>
      <div className={"flex flex-col justify-center items-center"}>
        <div
          className={
            "text-5xl font-light text-light font-serif text-center leading-[1.1] tracking-wide mt-2"
          }
        >
          {associationUser?.user?.firstName} <br />
          {associationUser?.user?.lastName}
        </div>
        <div
          className={
            "text-light/90 text-xs mt-2 uppercase tracking-[0.25rem] text-center"
          }
        >
          {associationUser?.associationRole?.name}
        </div>
      </div>
    </div>
  );
};

const CountryFlag: React.FC = () => {
  const { associationUser } = useAssociationUserShow();
  return (
    <div
      className={"flex flex-row justify-center items-center w-full z-10 mt-4"}
    >
      <Image
        className={"rounded-full"}
        src={getCountryFlagUrl(associationUser?.association?.country)}
        width={70}
        height={70}
      />
    </div>
  );
};

const UserDetails: React.FC = () => {
  const { associationUser } = useAssociationUserShow();
  const validMembership = getValidMembership(associationUser?.membership);
  const membershipDate = validMembership
    ? `${new Date(validMembership.endDate).toLocaleDateString()}`
    : undefined;
  return (
    <div className={"flex flex-row justify-between mt-auto"}>
      <UserDetailsBlock
        label={"Date of birth"}
        value={
          associationUser?.user?.dateOfBirth
            ? new Date(associationUser?.user?.dateOfBirth).toLocaleDateString()
            : "/"
        }
      />
      <UserDetailsBlock
        label={"Class"}
        value={associationUser?.associationGolfClass?.name || "/"}
      />
      <UserDetailsBlock label={"Valid date"} value={membershipDate || "/"} />
    </div>
  );
};

const UserDetailsBlock: React.FC<{ label: string; value?: string }> = ({
  label,
  value,
}) => {
  const { associationUser } = useAssociationUserShow();
  return (
    <div className={"flex flex-col text-light max-w-[120px]"}>
      <div className={"text-sm capitalize"}>{label}</div>
      <div className={"font-bold text-sm"}>{value}</div>
    </div>
  );
};

const getSpecialRuleOrDefault = (
  country: string | undefined,
  path: string,
  defaultValue: string | number | undefined
): number | string | undefined => {
  if (!country) {
    console.log("No country, returning defaultValue");
    return defaultValue;
  }

  return get(SPECIAL_RULES, `${country}.${path}`, defaultValue) as
    | string
    | number
    | undefined;
};
