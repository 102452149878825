import {useTableReturnType} from "@refinedev/core/dist/hooks/useTable/index";
import {ReactNode, createContext, useContext, useState, FC} from "react";
import { CrudFilters } from "@refinedev/core";

type SetFilters =useTableReturnType['setFilters']
interface TableFilterContextValue {
  filters: CrudFilters;
  setFilters: SetFilters;
}

const TableFilterContext = createContext<TableFilterContextValue | null>(null);

interface TableFilterProviderProps {
  filters: CrudFilters;
  setFilters: SetFilters
  children: ReactNode;
}

export const TableFilterProvider: FC<TableFilterProviderProps> = ({
  children,
  filters,
  setFilters,
}) => {
  return (
    <TableFilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </TableFilterContext.Provider>
  );
};

export const useTableFilter = () => {
  const context = useContext(TableFilterContext);
  if (!context) {
    throw new Error("useTableFilter must be used within a TableFilterProvider");
  }
  return context;
};
