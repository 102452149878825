import { CrudFilters, LogicalFilter } from "@refinedev/core";
import { mapOperator } from "./mapOperator";

export const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string } = {};

  if (filters) {
    filters.map((filter) => {
      if (filter.operator === "or") {
        for (const subFilter of filter.value) {
          const mappedOperator = mapOperator(subFilter.operator);
          queryFilters[
            `filter.${(subFilter as LogicalFilter).field}`
          ] = `$or:${mappedOperator}`;
          if (subFilter.value) {
            queryFilters[
              `filter.${(subFilter as LogicalFilter).field}`
            ] += `:${subFilter.value}`;
          }
        }
      }
      if (filter.operator === "and") {
        // throw new Error(
        //   `[@refinedev/simple-rest]: \`operator: ${filter.operator}\` is not supported. You can create custom data provider. https://refine.dev/docs/api-reference/core/providers/data-provider/#creating-a-data-provider`
        // );
        for (const subFilter of filter.value) {
          const mappedOperator = mapOperator(subFilter.operator);
          queryFilters[
            `filter.${(subFilter as LogicalFilter).field}`
          ] = `${mappedOperator}:${subFilter.value}`;
        }
      }

      if ("field" in filter) {
        const { field, operator, value } = filter;

        if (field === "search") {
          queryFilters[field] = value;
          return;
        }

        const mappedOperator = mapOperator(operator);
        queryFilters[`filter.${field}`] = `${mappedOperator}`;
        if (value) {
          queryFilters[`filter.${field}`] += `:${value}`;
        }
      }
    });
  }

  return queryFilters;
};
