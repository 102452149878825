import { IResourceComponentsProps, useShow } from "@refinedev/core";
import React from "react";
import { useParams } from "react-router-dom";
import { MembershipList } from "../../../components/membership/memebership-list";
import { useActiveAssociation } from "../../../context/active-association.context";
import { AssociationUserShowProvider } from "../../../context/user-show.context";
import {ResourceIdentifier} from "../../../resources";
import { AssociationUser } from "../../../sdk";
import { PgaCardPreview } from "../user-card/pga-card-preview";
import UserDetailsComponent from "./user-details.component";

export const MemberView: React.FC<IResourceComponentsProps> = () => {
  const activeAssociation = useActiveAssociation();

  const params = useParams();
  const { queryResult } = useShow<AssociationUser>({
    resource: ResourceIdentifier.AssociationUser,
    id: params.id,
  });
  const { data: associationUser, isLoading } = queryResult;

  return (
    <div className={'mb-20'}>
      {associationUser?.data?.id && (
        <AssociationUserShowProvider associationUser={associationUser?.data}>
          <div className={"flex flex-wrap w-full relative gap-20 justify-center md:justify-start"}>
            <div className={"flex flex-col gap-10 max-w-md overflow-auto"}>
              <MembershipList />
              <CardPreview />
            </div>
              <UserDetailsComponent />
          </div>
        </AssociationUserShowProvider>
      )}
    </div>
  );
};

const CardPreview = () => {
  return (
    <div className={"max-w-md rounded-xl shadow-medium"}>
      <PgaCardPreview />
    </div>
  );
};
