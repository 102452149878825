import { Input } from "@nextui-org/react";
import {
  CrudFilter,
  getDefaultFilter,
  useTableReturnType,
} from "@refinedev/core";
import { IconSearch } from "@tabler/icons-react";
import React, { FC } from "react";

export type TableSearchInputProps = {
  filters: useTableReturnType["filters"];
  setFilters: useTableReturnType["setFilters"];
  setCurrent: useTableReturnType["setCurrent"];
};
export const TableSearchInput: FC<TableSearchInputProps> = ({
  filters,
  setFilters,
  setCurrent,
}) => {
  return (
    <Input
      isClearable
      className="w-full sm:max-w-[20%]"
      placeholder="Search"
      aria-label="Search"
      startContent={<IconSearch width={12} />}
      value={getDefaultFilter("search", filters)}
      onClear={() => {
        setCurrent(1);
        setFilters([], "replace");
      }}
      onValueChange={(value) => {
        if (!value.trim()) {
          setCurrent(1);
          setFilters([], "replace");
          return;
        }

        setCurrent(1);
        setFilters([
          {
            field: "search",
            value: value,
            operator: "contains",
          },
        ]);
      }}
    />
  );
};
