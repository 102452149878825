import { Select, SelectItem } from "@nextui-org/react";
import { CrudFilter, LogicalFilter, useList } from "@refinedev/core";
import React, { Key } from "react";
import { useActiveAssociation } from "../../../../context/active-association.context";
import { useTableFilter } from "../../../../context/table-filter.context";
import { ResourceIdentifier } from "../../../../resources";
import {
  AssociationGolfClass,
  AssociationRole,
  GolfProfessionalLevel,
} from "../../../../sdk";

const GolfClassField = "golfClass";

export const GolfClassFilter = () => {
  const activeAssociation = useActiveAssociation();
  const { setFilters, filters } = useTableFilter();

  const { data: golfClasses } = useList<GolfProfessionalLevel>({
    resource: ResourceIdentifier.GolfProfessionalLevel,
    meta: {
      associationId: activeAssociation?.id,
    },
    queryOptions: {
      enabled: !!activeAssociation?.id,
    },
  });

  const updateFilters = (newFilters: CrudFilter[]) => {
    setFilters(newFilters, "replace");
  };

  /**
   * Updates the filters by setting a filter based on the provided value. If the value is
   * an array of numbers and non-empty, it adds a new filter object for the specific field
   * with the "in" operator. If the value is undefined or an empty array, it removes any
   * existing filter for the specified field.
   */
  const setFilter = (value: Key[] | undefined) => {
    const updatedFilters =
      value && value.length > 0
        ? filters
            .filter(
              (filter) => (filter as LogicalFilter)?.field !== GolfClassField
            )
            .concat({ field: GolfClassField, operator: "in", value })
        : filters.filter(
            (filter) => (filter as LogicalFilter)?.field !== GolfClassField
          );

    updateFilters(updatedFilters);
  };

  return (
    <Select
      className="max-w-xs"
      label="Golf Professional Level"
      placeholder="All"
      selectedKeys={
        filters.filter(
          (filter) => (filter as LogicalFilter)?.field === GolfClassField
        )?.[0]?.value || []
      }
      onSelectionChange={(selection) => {
        if (selection === "all") {
          setFilter([]);
        } else {
          setFilter(Array.from(selection).map((role) => role));
        }
      }}
      selectionMode="multiple"
    >
      {(golfClasses?.data || [])?.map((golfClass) => (
        <SelectItem key={golfClass.id}>{golfClass.name}</SelectItem>
      ))}
    </Select>
  );
};
