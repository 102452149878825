import { IResourceComponentsProps } from "@refinedev/core";
import React from "react";
import { useAssociationUserShow } from "../../../context/user-show.context";

import { CardQrCode } from "./pga-user-card-components/card-qr-code"; // requires a loader
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation  } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { CpgCard } from "./pga-user-card-components/cpg-card";
import { PgaUserCard } from "./pga-user-card-components/pga-user-card";

// This card difference only in added navigation, since the preview will mostly be used on PC-s
export const PgaCardPreview: React.FC<IResourceComponentsProps> = () => {
  const { associationUser } = useAssociationUserShow();

  return (
    <div className={"h-full w-full bg-light flex flex-col rounded-xl"}>
      <UserViewHeader />
      <div className={""}>
        <Swiper
          className={'pb-12'}
          initialSlide={1}
          spaceBetween={15}
          slidesPerView={1.2}
          centeredSlides={true}
          modules={[Pagination, Navigation]}
          pagination={{
            type: "bullets",
            clickable: true,
          }}
          navigation={{
            enabled: true
          }}
          keyboard={true}
          cssMode={true}
        >
          <SwiperSlide>
            <CardQrCode/>
          </SwiperSlide>
          <SwiperSlide>
            <PgaUserCard/>
          </SwiperSlide>
          {(associationUser?.golfClass ||
            associationUser?.coachClass ||
            !!associationUser?.certificates?.length) && (
            <SwiperSlide>{<CpgCard/>}</SwiperSlide>
          )}

          <div className="swiper-pagination"></div>
        </Swiper>

      </div>
    </div>
  );
};

const UserViewHeader = () => {
  return (
    <div className={"flex items-center py-6 px-2 relative justify-self-start"}>
      <div className={"text-center w-full text-xs font-bold tracking-[0.3em]"}>
        VIRTUAL CPG CARD
      </div>
    </div>
  );
};
