import {Association, AssociationMembership} from "../sdk";
import { AssociationFeatureFlagEnum } from "./association-feature-flag.enum";

export const getDotNotationPropertyValue = (
  obj: Record<string, any>,
  path: string
) => {
  return path.split(".").reduce((acc: any, part: any) => acc && acc[part], obj);
};

export const hasAssociationFeatureFlag = ({
  association,
  flagName,
}: {
  association?: Association | null;
  flagName: keyof typeof AssociationFeatureFlagEnum;
}) => {
  return association?.featureFlags?.some(
    (el) => el.name === flagName && el.isEnabled
  );
};

export const getValidMembership = (memberships?: AssociationMembership[]) => {
  if (!memberships) return false;
  const currentDate = Date.now();
  return memberships.find(
    (membership) =>
      new Date(membership["startDate"]).getTime() < currentDate &&
      new Date(membership["endDate"]).getTime() > currentDate
  );
};

export const getAvatarInitials = (name: string) => {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("");
};
