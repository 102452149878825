import QRCodeStyling from "qr-code-styling";
import { useEffect, useRef, useState } from "react";
import { AssociationUser } from "../../../../sdk";

export const UserQrCode = ({
  associationUser,
}: {
  associationUser: AssociationUser;
}) => {
  const primaryColor = "#14282b"; //'#14282b'
  const [qrCodeStyling] = useState<QRCodeStyling>(
    new QRCodeStyling({
      // width: 250,
      // height: 250,
      type: "svg",
      data: `${import.meta.env.VITE_PUBLIC_ADMIN_URL}/public/${
        associationUser.user.id
      }/${associationUser.id}`,
      margin: 0,
      qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "Q" },
      imageOptions: { hideBackgroundDots: true, imageSize: 0.7, margin: 5 },
      dotsOptions: {
        type: "extra-rounded",
        color: "#6a1a4c",
        gradient: {
          type: "radial",
          rotation: 0,
          colorStops: [
            { offset: 0, color: primaryColor },
            { offset: 1, color: primaryColor },
          ],
        },
      },
      backgroundOptions: { color: "transparent" },
      dotsOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: primaryColor,
          color2: primaryColor,
          rotation: "0",
        },
      },
      cornersSquareOptions: {
        type: "extra-rounded",
        color: "#14282b",
        gradient: {
          type: "radial",
          rotation: 0,
          colorStops: [
            { offset: 0, color: primaryColor },
            { offset: 1, color: primaryColor },
          ],
        },
      },
      cornersSquareOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: primaryColor,
          color2: primaryColor,
          rotation: "0",
        },
      },
      cornersDotOptions: { type: "", color: primaryColor },
      cornersDotOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000000",
          color2: "#000000",
          rotation: "0",
        },
      },
      backgroundOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#ffffff",
          color2: "#ffffff",
          rotation: "0",
        },
      },
    } as any)
  );

  const [qrCode, setQrCode] = useState<any | null>();

  useEffect(() => {
    qrCodeStyling.getRawData("svg").then((dataUrl) => {
      if (dataUrl) {
        console.log("Data url", dataUrl);
        setQrCode(URL.createObjectURL(dataUrl));
      }
    });
  }, []);

  return qrCode && <img src={qrCode} className={"object-fit"} />;
};
