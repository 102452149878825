import { IconInfoCircle } from "@tabler/icons-react";
import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Avatar,
  Chip,
  Tooltip,
} from "@nextui-org/react";
import { UserUpsertForm } from "../../../components/forms/upsert-user-form/user-upsert-form";
import { useDrawer } from "../../../context/drawer.context";
import { useAssociationUserShow } from "../../../context/user-show.context";
import { useFileEntityUrl } from "../../../hooks";
import { getCountryFlagUrl } from "../../../lib/flag-map";
import { getAvatarInitials } from "../../../lib/utils";

export default function UserDetails() {
  const { toggleDrawer } = useDrawer();
  const { associationUser } = useAssociationUserShow();

  return (
    <Card className="w-full max-w-md">
      <CardHeader className="relative flex h-[120px] lg:h-[160px] flex-col justify-end overflow-visible">
        <Avatar
          className="h-24 w-24 lg:h-32 lg:w-32 border-4 border-secondary bg-secondary text-light text-md"
          name={`${associationUser?.user?.firstName} ${associationUser?.user?.lastName}`}
          getInitials={getAvatarInitials}
          src={useFileEntityUrl(associationUser?.user?.photoId)}
        />
        <Button
          className="absolute right-3 top-3"
          radius="full"
          size="sm"
          color={"secondary"}
          variant="shadow"
          onPress={() => {
            toggleDrawer({
              opened: true,
              content: <UserUpsertForm user={associationUser} />,
              headerText: "Edit user",
            });
          }}
        >
          Edit Profile
        </Button>
      </CardHeader>
      <CardBody className="space-y-2 px-6">
        <CellValue label="First Name" value={associationUser?.user.firstName} />
        <CellValue label="Last Name" value={associationUser?.user.lastName} />
        <CellValue label="Email" value={associationUser?.user.email} />

        <hr />

        <CellValue
          label="Role"
          value={associationUser?.associationRole?.name}
        />
        <CellValue
          label="Association Golf Class"
          value={associationUser?.associationGolfClass?.name}
        />
        <CellValue
          label="Coach class"
          value={associationUser?.coachClass?.name}
        />
        <CellValue
          label="Certificates"
          tooltip={"Certificates are inserted by the user."}
          value={
            <div className={"flex flex-wrap flex-row gap-2 justify-end"}>
              {associationUser?.certificates?.map((certificate) => {
                return <Chip>{certificate.name}</Chip>;
              })}
            </div>
          }
        />

        <hr />
        <CellValue
          label="Gender"
          value={
            <span className={"capitalize"}>
              {associationUser?.user?.gender}
            </span>
          }
        />
        <CellValue label="Address" value={associationUser?.user.address} />
        <CellValue label="City" value={associationUser?.user.city} />
        <CellValue label="Zip Code" value={associationUser?.user.zip} />
        <CellValue label="Phone Number" value={associationUser?.user.phone} />
        <CellValue label="Birthday" value={associationUser?.user.dateOfBirth} />
        <CellValue
          label="Country"
          value={
            <div className="flex gap-2">
              <p>{associationUser?.user?.country}</p>
              {associationUser?.user?.country && (
                <Avatar
                  alt="United Kingdom"
                  className="h-6 w-6 shadow-none backdrop-blur-none"
                  getInitials={getAvatarInitials}
                  src={getCountryFlagUrl(associationUser?.user?.country)}
                />
              )}
            </div>
          }
        />
      </CardBody>
    </Card>
  );
}

export type CellValueProps = React.HTMLAttributes<HTMLDivElement> & {
  label: string;
  value: React.ReactNode;
  tooltip?: string;
};

const CellValue = React.forwardRef<HTMLDivElement, CellValueProps>(
  ({ label, tooltip, value, children, ...props }, ref) => (
    <div
      ref={ref}
      className="flex items-center justify-between py-2"
      {...props}
    >
      <div className="text-small text-default-500">
        <div className={"flex flex-row items-center "}>
          <span>{label}</span>
          {tooltip && (
            <Tooltip content={tooltip}>
              <IconInfoCircle color={"blue"} size={14} />
            </Tooltip>
          )}
        </div>
      </div>
      <div className="text-small font-medium">{value || children}</div>
    </div>
  )
);
