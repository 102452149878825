import {ActiveMembershipFilter} from "./filters/active-membership-filter.component";
import {AssociationGolfClassFilter} from "./filters/association-golf-class-filter.component";
import { RoleFilter } from "./filters/association-roles-filter.component";
import {GolfClassFilter} from "./filters/golf-class-filter.component";

export const MemberListFilters = () => {
  return (
    <div className={"flex flex-wrap mb-5 w-full gap-3"}>
      <RoleFilter />
      <AssociationGolfClassFilter />
      <ActiveMembershipFilter/>
      <GolfClassFilter />
    </div>
  );
};
