import {Select, SelectItem} from "@nextui-org/react";
import {CrudFilter, LogicalFilter, useEditButton, useList} from "@refinedev/core";
import React, {Key, useEffect} from "react";
import {useActiveAssociation} from "../../../../context/active-association.context";
import {useTableFilter} from "../../../../context/table-filter.context";
import {ResourceIdentifier} from "../../../../resources";
import {AssociationRole} from "../../../../sdk";


const RoleFilterField = "associationRole";

export const RoleFilter = () => {
  const activeAssociation = useActiveAssociation();
  const { setFilters, filters } = useTableFilter();

  const { data: roles } = useList<AssociationRole>({
    resource: ResourceIdentifier.AssociationRole,
    meta: {
      associationId: activeAssociation?.id,
    },
    queryOptions: {
      enabled: !!activeAssociation?.id,
    },
  });

  const updateFilters = (newFilters: CrudFilter[]) => {
    setFilters(newFilters, "replace");
  };

  /**
   * Updates the filters by setting a filter based on the provided value. If the value is
   * an array of numbers and non-empty, it adds a new filter object for the specific field
   * with the "in" operator. If the value is undefined or an empty array, it removes any
   * existing filter for the specified field.
   */
  const setFilter = (value: Key[] | undefined) => {
    const updatedFilters = value && value.length > 0
      ? filters.filter((filter) => (filter as LogicalFilter)?.field !== RoleFilterField)
        .concat({ field: RoleFilterField, operator: "in", value })
      : filters.filter((filter) => (filter as LogicalFilter)?.field !== RoleFilterField);

    updateFilters(updatedFilters);
  };


  return (
    <Select
      className="max-w-xs"
      label="Role"
      placeholder="All"
      selectedKeys={filters.filter((filter) => (filter as LogicalFilter)?.field === RoleFilterField)?.[0]?.value || []}
      onSelectionChange={(selection) => {
        if (selection === "all") {
          setFilter([]);
        } else {
          setFilter(Array.from(selection).map((role) => role));
        }
      }}
      selectionMode="multiple"
    >
      {(roles?.data || [])?.map((role) => (
        <SelectItem key={role.id}>{role.name}</SelectItem>
      ))}
    </Select>
  );
};
