import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Pagination,
} from "@nextui-org/react";
import { IconChevronDown } from "@tabler/icons-react";
import React, { FC } from "react";

export type TableBottomContentProps = {
  current: number;
  pageCount: number;
  setCurrent: (page: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
};

export const TableBottomContent: FC<TableBottomContentProps> = ({
  current,
  pageCount,
  setCurrent,
  pageSize,
  setPageSize,
}) => {
  return (
    <div className="flex w-full gap-2 justify-center">
      <Pagination
        isCompact={false}
        showControls
        showShadow
        color="primary"
        page={current}
        total={pageCount}
        onChange={(page) => setCurrent(page)}
      />

      <Dropdown>
        <DropdownTrigger>
          <Button variant="bordered" className="capitalize">
            {pageSize}
            <IconChevronDown size={12} />
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          aria-label="Select number of rows per page"
          variant="flat"
          color="primary"
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={[`${pageSize}`]}
          onSelectionChange={(rows) => {
            setPageSize(+(rows as Set<string>).values().next().value);
          }}
        >
          {[5, 10, 20, 40, 50].map((rowsPerPage) => {
            return (
              <DropdownItem
                key={`${rowsPerPage}`}
              >{`${rowsPerPage}`}</DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
