import { Card, CardBody, cn } from "@nextui-org/react";
import React, { FC, HTMLAttributes } from "react";

export const UserViewCard: FC<{
  children: React.ReactNode;
  additionalClasses?: HTMLAttributes<"div">["className"];
}> = ({ children, additionalClasses }) => {
  return (
    <Card
      fullWidth={true}
      shadow={"none"}
      className={cn(
        "rounded-4xl h-[575px] relative overflow-hidden",
        additionalClasses
      )}
    >
      <CardBody className={"p-0 overflow-hidden"}>{children}</CardBody>
    </Card>
  );
};
