import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Image,
} from "@nextui-org/react";
import React from "react";
import { useAssociationUserShow } from "../../../../context/user-show.context";
import { CpgSponsors } from "./cpg-sponsors";
import { OfficialCgpCardHeader } from "./official-cgp-card-header";
import { UserQrCode } from "./user-qr-code";
import { UserViewCard } from "./user-view-card";

export const CardQrCode = () => {
  return (
    <UserViewCard additionalClasses={"bg-primary"}>
      <div className={"flex items-center flex-col flex-grow select-none"}>
        <HeaderImage />
        <QrCodeElement />
      </div>
    </UserViewCard>
  );
};

export const HeaderImage: React.FC = () => {
  return (
    <div className={"flex justify-center z-10 w-full relative o"}>
      <div
        className={"w-40 h-40  rounded-full flex flex-col justify-center mt-10"}
      >
        <Image
          src={`${
            import.meta.env.VITE_PUBLIC_BACKEND_API_BASE_URL
          }/cpg-logo.png`}
          height={180}
          width={180}
        />
      </div>
    </div>
  );
};
const QrCodeElement = () => {
  const { associationUser } = useAssociationUserShow();
  return associationUser ? (
    <div className={"h-[250px] w-[250px] mt-10 bg-light p-8 rounded-3xl"}>
      <UserQrCode associationUser={associationUser} />
    </div>
  ) : null;
};
