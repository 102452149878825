import React from "react";
import { useAssociationUserShow } from "../../../../context/user-show.context";
import { AssociationFeatureFlagEnum } from "../../../../lib/association-feature-flag.enum";
import { hasAssociationFeatureFlag } from "../../../../lib/utils";
import { HeaderImage } from "./card-qr-code";
import { UserViewCard } from "./user-view-card";

export const CpgCard = () => {
  return (
    <UserViewCard additionalClasses={"bg-secondary"}>
      <HeaderImage />
      <UserInfoName />
    </UserViewCard>
  );
};

const UserInfoName: React.FC = () => {
  const { associationUser } = useAssociationUserShow();

  if (!associationUser) {
    return null;
  }

  const isCardHideCoachClass = hasAssociationFeatureFlag({
    association: associationUser.association,
    flagName: AssociationFeatureFlagEnum.HIDE_COACH_CLASS,
  });
  return (
    <div className={"flex justify-center my-3 z-10"}>
      <div className={"flex flex-col justify-center items-center"}>
        <div className={"relative mt-5"}>
          <div
            className={
              "flex flex-col text-center text-light text-md font-semibold uppercase tracking-widest mt-0 mb-8 gap-y-8 gap-x-2"
            }
          >
            <div>
              Golf Professional level
              <br />
              <span className={"font-normal"}>
                {associationUser?.golfClass?.name || "/"}
              </span>
            </div>
            {!isCardHideCoachClass && (
              <div>
                Golf Coach level <br />
                <span className={"font-normal"}>
                  {associationUser?.coachClass?.name || "/"}
                </span>
              </div>
            )}
            <div>
              Certificates <br />
              <span className={"font-normal flex flex-col"}>
                {associationUser?.certificates?.map((certificate) => (
                  <span key={certificate.id}>{certificate.name}</span>
                ))}
                {!associationUser?.certificates.length && "/"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
