import {
  Button,
  Chip,
  cn,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Progress,
  Skeleton,
  SortDescriptor,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
  User,
} from "@nextui-org/react";
import {
  CrudSort,
  IResourceComponentsProps,
  useApiUrl,
  useCreate,
  useDelete,
  useGetIdentity,
  useList,
  useNavigation,
  useTable,
  useTableReturnType,
} from "@refinedev/core";
import {
  IconChevronDown,
  IconDotsVertical,
  IconMailFast,
  IconPencil,
  IconPlus,
  IconUserX,
} from "@tabler/icons-react";
import React, { FC, useCallback, useState } from "react";
import { ProgressBar } from "react-aria-components";
import { useParams } from "react-router-dom";
import { UserUpsertForm } from "../../../components/forms/upsert-user-form/user-upsert-form";
import { TableSearchInput } from "../../../components/table/search-input";
import { TableBottomContent } from "../../../components/table/table-bottom-content";
import { TableTitle } from "../../../components/table/table-title";
import { ConfirmationModal } from "../../../components/ui/confirmation-modal";
import { useActiveAssociation } from "../../../context/active-association.context";
import { useDrawer } from "../../../context/drawer.context";
import { TableFilterProvider } from "../../../context/table-filter.context";
import {
  getAvatarInitials,
  getDotNotationPropertyValue,
  getValidMembership,
} from "../../../lib/utils";
import { ResourceIdentifier } from "../../../resources";
import { AssociationUser, User as Identity } from "../../../sdk";
import { MemberListFilters } from "./member-list-filters.component";

type Selection = string[] | undefined;
type Column = {
  header: string;
  key: string;
  sortable?: boolean;
  sortableKey?: string;
  align?: "center" | "start" | "end";
  width?: number;
  valueKey?: string;
};
const columns: Column[] = [
  {
    header: "User",
    key: "user",
    sortable: true,
    sortableKey: "user.firstName",
    align: "start",
  },
  // {
  //   header: "Email",
  //   key: "email",
  //   valueKey: "user.email",
  //   sortable: true,
  //   sortableKey: "user.email",
  // },

  { header: "Role", key: "associationRole.name", sortable: true, width: 200 },
  {
    header: "Golf class",
    key: "associationGolfClass.name",
    sortable: true,
    width: 200,
  },
  { header: "Membership", key: "membership", sortable: false, width: 50 },
  { header: "Actions", key: "actions", sortable: false },
];

export const MembersList: React.FC<IResourceComponentsProps> = () => {
  const routeParams = useParams();
  const activeAssociation = useActiveAssociation();
  const { mutateAsync: sendInvite } = useCreate();

  const { data: user } = useGetIdentity<Identity>();

  const {
    tableQuery,
    pageCount,
    current,
    pageSize,
    filters,
    setCurrent,
    setPageSize,
    setSorters,
    setFilters,
  } = useTable<AssociationUser>({
    resource: ResourceIdentifier.AssociationUser,
    meta: {
      associationId: activeAssociation?.id,
    },
    syncWithLocation: true,
    pagination: {
      pageSize: 15,
    },
  });

  const { edit, show, create, showUrl } = useNavigation();
  const { mutate: deleteProduct } = useDelete();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [deleteItem, setDeleteItem] = useState<null | AssociationUser>(null);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "user",
    direction: "ascending",
  });

  const [selected, setSelected] = useState<Selection>();
  const { toggleDrawer } = useDrawer();

  const apiUrl = useApiUrl();

  function getUpsertForm(item?: AssociationUser) {
    return <UserUpsertForm user={item} />;
  }

  const renderCell = useCallback(
    (columnKey: string, item: any) => {
      const column = columns.find((column) => column.key === columnKey);
      if (column?.valueKey) {
        return (
          <TableCell>
            {getDotNotationPropertyValue(item, column.valueKey)}
          </TableCell>
        );
      }

      if (columnKey === "user") {
        const user = item.user;
        return (
          <TableCell>
            <User
              avatarProps={{
                radius: "lg",
                src: user?.photoId
                  ? `${apiUrl}/files/${user?.photoId}`
                  : undefined,
                className: user?.photoId ? "bg-light" : "bg-primary",
                color: "primary",
                name: `${user.firstName} ${user.lastName}`,
                getInitials: getAvatarInitials,
              }}
              description={user.email}
              name={`${user.firstName} ${user.lastName}`}
            ></User>
          </TableCell>
        );
      }

      if (columnKey === "membership") {
        const memberships = item.membership;
        const validMembership = getValidMembership(memberships);
        return (
          <TableCell>
            {validMembership && (
              <Chip size={"sm"} variant={"flat"} color="success">
                Active
              </Chip>
            )}
          </TableCell>
        );
      }

      if (columnKey === "actions") {
        return (
          <TableCell>
            <div className="flex items-center justify-center max-w-[30px]">
              <Dropdown>
                <DropdownTrigger>
                  <Button variant="ghost" isIconOnly={true}>
                    <IconDotsVertical className={"h-4 w-4"} />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  variant="faded"
                  aria-label="Dropdown menu with icons"
                >
                  <DropdownItem
                    key="new"
                    isDisabled={item.user.id === user?.id}
                    onPress={async () => {
                      await sendInvite({
                        resource: ResourceIdentifier.AssociationInvite,
                        meta: {
                          associationId: activeAssociation?.id,
                        },
                        values: {
                          userIds: [item.user.id],
                        },
                        successNotification: {
                          message: "Successfully sent invites",
                          type: "success",
                        },
                        errorNotification: (error) => {
                          return {
                            message: "Error sending notification",
                            description: error?.message,
                            type: "error",
                            position: "top-center",
                          };
                        },
                      });
                    }}
                    startContent={<IconMailFast className={"h-4 w-4"} />}
                  >
                    Send invite
                  </DropdownItem>
                  {item.user.id !== user?.id &&
                    item.associationRole.hasAdminAccess && (
                      <DropdownItem
                        key="sendAdminInvites"
                        isDisabled={
                          item.user.id === user?.id ||
                          !item.associationRole.hasAdminAccess
                        }
                        onPress={async () => {
                          await sendInvite({
                            resource: ResourceIdentifier.AssociationAdminInvite,
                            meta: {
                              associationId: activeAssociation?.id,
                            },
                            values: {
                              userIds: [item.user.id],
                            },
                            successNotification: {
                              message: "Successfully sent admin invite email",
                              type: "success",
                            },
                            errorNotification: (error) => {
                              return {
                                message: "Error sending admin invite email",
                                description: error?.message,
                                type: "error",
                                position: "top-center",
                              };
                            },
                          });
                        }}
                        startContent={<IconMailFast className={"h-4 w-4"} />}
                      >
                        Send Manager invite
                      </DropdownItem>
                    )}
                  <DropdownItem
                    key="sendMemershipCardLink"
                    onPress={async () => {
                      await sendInvite({
                        resource: ResourceIdentifier.MembershipCardLink,
                        meta: {
                          associationId: activeAssociation?.id,
                        },
                        values: {
                          userIds: [item.user.id],
                        },
                        successNotification: {
                          message: "Successfully sent membership card link",
                          type: "success",
                        },
                        errorNotification: (error) => {
                          return {
                            message: "Error sending memebership card link",
                            description: error?.message,
                            type: "error",
                            position: "top-center",
                          };
                        },
                      });
                    }}
                    startContent={<IconMailFast className={"h-4 w-4"} />}
                  >
                    Send card link
                  </DropdownItem>
                  <DropdownItem
                    key="edit"
                    onPress={() => {
                      toggleDrawer({
                        opened: true,
                        content: getUpsertForm(item),
                        headerText: "Edit user",
                      });
                    }}
                    startContent={<IconPencil className="h-4 w-4" />}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    key="delete"
                    className="text-danger"
                    color="danger"
                    isDisabled={item.user.id === user?.id}
                    onPress={() => {
                      setDeleteItem(item);
                      onOpen();
                    }}
                    startContent={
                      <IconUserX className={cn("h-4 w-4", "text-danger")} />
                    }
                  >
                    Remove
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </TableCell>
        );
      }

      return (
        <TableCell>{getDotNotationPropertyValue(item, columnKey)}</TableCell>
      );
    },
    [tableQuery?.data?.data, user]
  );

  return (
    <>
      <TableFilterProvider filters={filters} setFilters={setFilters}>
        <MemberListFilters />
      </TableFilterProvider>
      <Table
        id={"members-table"}
        isCompact={true}
        selectionMode={"multiple"}
        color={"primary"}
        isStriped={false}
        aria-label="Members table"
        sortDescriptor={sortDescriptor}
        onSelectionChange={(e) => {
          if (e === "all") {
            setSelected(tableQuery?.data?.data.map((el) => el.id));
          } else {
            setSelected([...e].map((el) => el) as any);
          }
        }}
        onSortChange={(e) => {
          const sorter: CrudSort = {
            order: e.direction === "ascending" ? "asc" : "desc",
            field: e.column as string,
          };

          const field = columns.find(
            (column) => column.key === e.column
          )?.sortableKey;

          if (field) {
            sorter.field = field;
          }

          setSorters([sorter]);
          setSortDescriptor(e);
        }}
        onRowAction={(item) => {
          // const resource = `/association/${activeAssociation?.id}/user`;
          show(ResourceIdentifier.AssociationUser, `${item}`);
        }}
        topContent={
          <TopContent
            setCurrent={setCurrent}
            setFilters={setFilters}
            filters={filters}
            selected={tableQuery?.data?.data.filter((el) =>
              selected?.includes(el.id)
            )}
          />
        }
        bottomContent={
          <TableBottomContent
            current={current}
            pageCount={pageCount}
            setCurrent={setCurrent}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        }
      >
        <TableHeader columns={columns}>
          {(column) => {
            if (column.key === "actions") {
              return (
                <TableColumn
                  allowsSorting={column.sortable}
                  key={column.key}
                  className="text-center px-4 w-20"
                  align={column.align}
                >
                  {column.header}
                </TableColumn>
              );
            }
            return (
              <TableColumn
                className="text-start"
                allowsSorting={column.sortable}
                width={column.width}
                key={column.key}
                align={column.align}
              >
                {column.header}
              </TableColumn>
            );
          }}
        </TableHeader>
        {tableQuery?.data?.data.length ? (
          <TableBody
            isLoading={tableQuery.isLoading || tableQuery.isFetching}
            items={tableQuery?.data?.data}
            loadingContent={<TableLoadingIndicator />}
          >
            {(item) => {
              return (
                <TableRow className={"cursor-pointer"} key={item.id}>
                  {(columnKey) => {
                    return renderCell(columnKey as string, item);
                  }}
                </TableRow>
              );
            }}
          </TableBody>
        ) : (
          <TableBody
            isLoading={tableQuery.isLoading || tableQuery.isFetching}
            emptyContent={"No rows to display."}
            loadingContent={<TableLoadingIndicator />}
          >
            {[]}
          </TableBody>
        )}
      </Table>
      {isOpen ? (
        <ConfirmationModal
          isOpen={isOpen}
          title={"Remove user from association"}
          onConfirm={() =>
            deleteProduct({
              resource: ResourceIdentifier.AssociationUser,
              meta: {
                associationId: activeAssociation?.id,
              },
              id: deleteItem?.id!,
              successNotification: {
                message: "Successfully removed user from association",
                type: "success",
              },
              errorNotification: (error) => {
                return {
                  message: "Error removing user from association",
                  description: error?.message,
                  type: "error",
                  position: "top-center",
                };
              },
            })
          }
          onOpenChange={onOpenChange}
          warningMessage={
            <>
              You are about to remove the{" "}
              <span className={"text-warning"}>{deleteItem?.user.email}</span>{" "}
              from the association. This action is irreversible.
            </>
          }
        />
      ) : null}
    </>
  );
};

const TableLoadingIndicator = () => {
  return (
    <>
      <div className={"absolute w-full top-0"}>
        <Progress aria-label={'Loading...'} color={"secondary"} size={"sm"} isIndeterminate />
      </div>
      {/*<div className={"absolute w-full bottom-0"}>*/}
      {/*  <Progress color={"secondary"} size={"sm"} isIndeterminate />*/}
      {/*</div>*/}
    </>
  );
};

export type TableTopContentProps = {
  setCurrent: useTableReturnType["setCurrent"];
  setFilters: useTableReturnType["setFilters"];
  filters: useTableReturnType["filters"];
  selected: AssociationUser[] | undefined;
};
export const TopContent: FC<TableTopContentProps> = ({
  setCurrent,
  setFilters,
  filters,
  selected,
}) => {
  const { toggleDrawer } = useDrawer();
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between gap-3">
        <TableTitle title={"Members"}></TableTitle>
        <Button
          color="default"
          variant="bordered"
          startContent={<IconPlus className="h-4 w-4" />}
          onPress={() => {
            toggleDrawer({
              opened: true,
              content: <UserUpsertForm />,
              headerText: `Add member`,
            });
          }}
        >
          Add member
        </Button>
      </div>
      <div className="flex justify-between items-end">
        <MultipleSelectionDropdown selected={selected} />
        <TableSearchInput
          setFilters={setFilters}
          filters={filters}
          setCurrent={setCurrent}
        />
      </div>
    </div>
  );
};

const MultipleSelectionDropdown = ({
  selected,
}: {
  selected: AssociationUser[] | undefined;
}) => {
  const [isOpen, onOpenChange] = useState(false);

  const { mutateAsync: sendInvite } = useCreate();
  const activeAssociation = useActiveAssociation();

  return (
    <>
      {isOpen ? (
        <ConfirmationModal
          isOpen={isOpen}
          confirmationButtonColor={"primary"}
          title={"Send user invites"}
          onConfirm={async () => {
            await sendInvite({
              resource: ResourceIdentifier.AssociationInvite,
              meta: {
                associationId: activeAssociation?.id,
              },
              values: {
                userIds: selected?.map((el) => el.user.id),
              },
              successNotification: {
                message: "Successfully sent invite emails",
                type: "success",
              },
              errorNotification: (error) => {
                return {
                  message: "Error sending notification",
                  description: error?.message,
                  type: "error",
                  position: "top-center",
                };
              },
            });
          }}
          onOpenChange={onOpenChange}
          confirmationText={"Send"}
          warningMessage={
            <>You are about to send {selected!.length} invitation emails.</>
          }
        />
      ) : null}
      <Dropdown>
        <DropdownTrigger>
          <Button
            variant="ghost"
            isDisabled={!selected || selected?.length === 0}
          >
            {(selected?.length || 0) > 0
              ? selected?.length + " selected"
              : "No selection"}
            <IconChevronDown className={"w-4 h-4"} />
          </Button>
        </DropdownTrigger>
        <DropdownMenu variant="faded" aria-label="Dropdown menu with icons">
          <DropdownItem
            onPress={() => {
              onOpenChange(true);
            }}
            key="sendMultipleInvites"
            startContent={<IconMailFast className={"h-4 w-4"} />}
          >
            Send invite
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
